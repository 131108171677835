.backButton {
    border: none;
    background: transparent;
    padding: 0;
  }
  .backButton i{
    font-size: 27px;
  }
  
  .backButton i:before{
      position: relative;
      top: 6px;
  }
  .filterContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 30px; 
    margin-top: 20px;
    flex-wrap: wrap;
  }
  .userFilter {
    width: 100%;
    margin-right: 10px;
    flex: 0 0 48%;
    margin-bottom: 10px;
  }
  .filterBtn {
    margin-right: 10px;
  }
  .inputTitle {
    height: 40px !important;
  }
  .flexDivAs{
    display: flex;
  }
