.showMore {
    text-align: center;
    font-size: medium;
    color: black;
    padding: 5px;
}

.showLess {
    text-align: center;
    font-size: medium;
    color: black;
    padding: 5px;
}