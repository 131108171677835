.backButton {
  border: none;
  background: transparent;
  padding: 0;
}

.backButton i {
  font-size: 27px;
}

.backButton i:before {
  position: relative;
  top: 6px;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 20px;
}

.userFilter {
  width: 100%;
  margin-right: 10px;
}

.filterBtn {
  margin-right: 10px;
}

.inputTitle {
  height: 40px !important;
}

.descriptionViwer {
  width: 100%;
  max-width: 100%;
}

.descriptionViwer img {
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}