//fonts

$font_0: Lato;
$font_1: sans-serif;
$font-themify: themify;
$font-awesome: FontAwesome;
$font-ICO: IcoFont;
$font-work-sans: work-Sans;
// $font-nunito: Nunito;
$font-serif: sans-serif;

//colors
$white: #ffffff;
$white-dark: #eeeeee;
$black: #000000;
$grey01:#111111;
$dark-font: #222222;
$grey-dark: #2d2a25;
$font-color:#333333;
$grey: #777777;
$grey-light: #a1a1a1;
$border:#dddada;
$round-border: #dddddd;
$grey-lighter: #f9f9f9;
$theme-deafult: #ff8084;
$grey-darken: #393230;
$grey-link: #948e8c;
$grey-font: #938d8c;
$dark-footer: #2e2726;
$form-bg: #f5f2f2;
$grey-shade: #7f786d;
$darker-footer: #25221e;
$border-color: #38352f;
$border-grey: #f1f5f4;
$font-grey: #aaaaaa;
$star-yellow: #ffa200;
$border-bottom-g:#525252;
$top-header: #f8f8f8;
$header-font: #999999;
$shadow: #ededed;
$box-border: #ecececa8;
$grey-about: #f7f7f7;
$grey2:#555555;
$grey3: #efefef;
$grey4: #444444;
$grey5: #888888;
$grey6:#6f6f6f;
$grey7:#40494f;
$grey8:#c0c0c0;
$sidebar-border: #f5f2f2;
$sidebar-color:#938d8c;
$modal: #ffba00;
$bg-color: #d0edff;
$bg-color1: #f1e7e6;
$bg-color2: #bfbfbf;
$light-grey: #d0edff;
$pink: #f1e7e6;
$blue: #bfbfbf;
$icon: #6f6f6f;
$color-red:#ff4c3b;
$gradient1: #01effc;
$gradient2: #485ff2;
$left-sidebar: #2b2b2b;

// dark layput variable //
$dark-body:#2b2b2b;
$dark-top:#232323;
$dark-border:#404040;
$dark-link:#cbcbcb;
$dark-span:#929292;
$dark-footer-bg:#383838;


$white-1:#f1f3f5;
$white-2:#eaedef;
$white-3:#e7eaec;
$white-4:#dee2e6;
$white-5:#cfd4da;


// Theme colors variables
$primary-color: $dark-font ;
$secondary-color: #13c9ca ;
$success-color: #81ba00  ;
$info-color   : #00a8ff  ;
$warning-color: #ffbc58;
$danger-color: #a5a5a5 ;
$light-color   : #f8f8f9 ;
$semi-dark  :#aaaaaa;
$light-semi-gray   :#eff0f1;
$light-gray   :#e8ebf2;
$dark-gray 	:#898989;
$dark-color   : #2a3142;
$gray-60   :#999999 ;
$transparent-color: transparent;
$auth-bg-color:#fafafa;
$light : #f6f6f6;
$light-text: #999;


// Tables settings
$horizontal-border-color:    #dee2e6;
$table-b-margin:             0px;
$table-footer-font-weight:   bold;
$table-inverse-color:        $white;
$table-hover-color:          $light;
$table-padding:              0.75rem;
$table-xl-padding:           1.25rem 2rem;
$table-xl-font:              120%;
$table-lg-padding:           .9rem 2rem;
$table-lg-font:              110%;
$table-de-padding:           .75rem 2rem;
$table-de-font:              100%;
$table-sm-padding:           .5rem 2rem;
$table-sm-font:              90%;
$table-xs-padding:           0.2rem 2rem;
$table-xs-font:              80%;
$horizontal-border-color:    #dee2e6;
$horizontal-padding:         .75rem 2rem;
$table-inverse-bg-color:     #292b2c;


// General variables
$theme-font-color: #1b252a;
$theme-body-font-color: #313131;
$theme-body-sub-title-color: #777777;

//sidabr main settings
$sidebar-width: 255px;
$sidebar-position: fixed;
$sidebar-background-color: $white;
$sidebar-shadow: 0 0 11px rgba(143, 164, 232, 0.08);
$sidebar-overflow: auto;
$sidebar-z-index: 9;
$sidebar-transition: 0.3s;

//main header left settings
$main-header-left-bg-color : $dark-font;// $transparent-color;
$main-header-padding : 12px;
$main-header-z-index : 9;
$main-header-right-padding: 0 40px;
$main-header-right-toggle-color : $primary-color;
$main-header-right-nav-right :  0px;
$main-header-right-nav-icon-size :  18px;
$main-header-right-nav-icon-color :  $primary-color;
$main-header-right-nav-icon-margin-top :  10px;

//page settings
$page-body-padding : 0 15px;
$page-body-bg-color: $light-color;
$page-body-margin-bottom: 0px;

$page-header-padding : 30px;
$page-title-font-size: 24px;
$page-title-margin-bottom: 0;
$page-title-font-weight: 800;
$page-title-text-tranform: uppercase;
$breadcrumb-size: 14px;
$breadcrumb-content: "/";
$breadcrumb-svg-icon-align: text-top;

//Header settings
$main-header-bg-color: $white;
$main-header-position: fixed;
$main-header-top: 0;
$main-header-z-index: 10;
$main-header-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);

//General tag settings
$body-bg-color: rgba(246, 246, 246, 0.6);
$body-font-size: 14px;
$body-font-color: $theme-body-font-color;
$ul-padding-left: 0px;
$ul-padding-right: 0px;
$ancher-color: $primary-color;
$btn-focus-box-shadow: none;
$all-focus-outline-color: $primary-color;

$paragraph-font-size : 13px;
$paragraph-line-height : 1.7;
$paragraph-letter-spacing : 0.7px;

$code-tag-color: $primary-color;
$code-tag-bg-color: $light-color;
$code-tag-padding: 3px;
$code-tag-margin: 0 3px;
$code-tag-border-radious: 2px;

$blockquote-padding : 15px;
$blockquote-border : 4px solid $light-gray;

$small-tag-padding: 10px;
$small-tag-color: $dark-color;

$pre-tag-bg-color: rgba(68, 102, 242, 0.05);
$pre-tag-padding: 20px 0;

$list-group-active-bg-color: $primary-color;
$list-group-active-border-color: $primary-color;
$list-group-img-size: 40px;
$list-group-margin: 10px;

//footer settings
$footer_bg_color : $white;
$footer_top_bgr_color : $light-color;
$footer_link_align : right;
$footer_dark_color : $dark-color;
$footer_dark__txt_color : $white;


//Card settings
$card-padding        :20px 30px;
$card-margin-bottom  :30px;
$card-border-width   :0px;
$card-border-color   :$light-color;
$card-border-radious :8px;
$card-box-shadow     :1px 5px 24px 0 rgba(68, 102, 242, 0.05);
$card-header-font-weight : 600;
$card-header-bg-color : $white;
$card-header-font-size : 18px;
$card-header-font-transform : capitalize;
$card-header-font-color : $theme-body-font-color;
$card-header-span-size : 12px;
$card-header-span-color : $theme-body-sub-title-color;
$card-body-bg-color : $transparent-color;
$card-footer-bg-color : $white;

//breadcrumb setting
$breadcrumb-ancher-color    : $white;
$breadcrumb-ancher-dark-color    : $black;

//buttons setting
$btn-font-size : 14px;
$btn-padding : 0.5rem 1.5rem;
$btn-lg-font-size: 18px;
$btn-sm-font-size: 12px;
$btn-xs-font-size: 11px;
$btn-xs-padding:  0.05rem 0.4rem;

//error input color start
$light-body-bg-color:#f1f4fb;


//sidebar profile settings
$sidebar-user-section-padding: 25px 10px;
$sidebar-user-section-shadow: 3px 2px 7px -1px rgba(127, 151, 249, 0.13);
$sidebar-profile-name-txt-color : $primary-color;
$sidebar-profile-name-txt-transfer : uppercase;
$sidebar-profile-name-txt-weight : 600;
$sidebar-profile-name-letter-specing : 1.5px;
$sidebar-profile-name-marging : 3px;
$sidebar-profile-sub-title-font-size: 10px;
$sidebar-profile-sub-title-margin: 0px;
$sidebar-profile-img-shadow: 0 0 15px rgba(68, 102, 242, 0.3);

//Sidebar main menu setting
$sidebar-menu-padding: 20px;
$sidebar-menu-list-style: none;
$sidebar-menu-list-margin: 0;

$sidebar-icon-size: 14px;
$sidebar-icon-margin: 14px;
$sidebar-icon-stroke-width: 3px;
$sidebar-font-size: 14px;
$sidebar-letter-specing: 0.5px;
$sidebar-txt-transform: capitalize;
$sidebar-font-weight: 600;
$sidebar-font-color: $theme-body-font-color;
$sidebar-padding-top: 12px;
$sidebar-padding-bottom: 12px;
$sidebar-sub-header-padding: 15px;
$sidebar-sub-header-margin: 0;

$sidebar-text-transform: capitalize;
$sidebar-heading-hover-padding: 3px;
$sidebar-hover-txt-color: $primary-color;
$sidebar-arrow-margin-top: 2px;
$sidebar-arrow-size: 15px;
$sidebar-arrow-color: $theme-body-font-color;

$sidebar-open-icon: "\f107";
$sidebar-close-icon:"\f105";
$sidebar-icon-font-family: $font-awesome;







