.filterContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    margin-top: 20px;
    
}

.userFilter {
    width: 100%;
    margin-right: 10px;
}

.filterBtn {
    margin-right: 10px;
}

.inputTitle {
    height: 40px !important;
}

@media (max-width:991px) {
    .filterContainer {
        flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0 15px;
    margin-bottom: 15px;

    }
    .userFilter {
        margin-bottom: 15px;
    }
}