.messenger {
    display: grid;
    width: 100%;
    background: #eeeef1;
    grid-template-columns: 350px auto;
    grid-template-rows: 60px auto 60px;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    height: calc(100vh - 270px);
}

.container {
    padding: 10px;
}

.scrollable {
    position: relative;
}

.sidebar {
    background: white;
    grid-row-start: 1;
    grid-row-end: span 3;
    margin-right: 10px;
    height: calc(100vh - 250px);
    overflow: auto;
}

.content {
    background: white;
    grid-row-start: 1;
    grid-row-end: span 3;
    height: calc(100vh - 270px);
    background: #fff;
}

.footer {
    grid-column-start: 2;
    background: white;
}

.usersList {
    display: flex;
    flex-direction: column;
}

.userList {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #d8d5d5;
}

.userList:hover {
    background: #eeeef1;
    cursor: pointer;
}

.userImage {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
}

.userName {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin: 0;
}

.lastMessage {
    font-size: 14px;
    color: #888;
    margin: 0;
}

.toolbar {
    display: flex;
    align-items: center;
    background-color: white;
    font-weight: 500;
    border-bottom: 1px solid #eeeef1;
    position: sticky;
    top: 0px;
}

.chatWindowTop {
    cursor: pointer;
    margin: 10px;
    display: flex;
    align-items: center;
    background-color: white;
    font-weight: 500;
    border-bottom: 1px solid #eeeef1;
    position: sticky;
    top: 0px;
}

.chatWindowUser {
    margin: 0;
    font-size: 16px;
    font-weight: 800;
}

.chatHistory {
    padding: 10px;
    height: calc(100vh - 470px);
    overflow-y: auto;
    background: #fff;
}

.message {
    display: flex;
    flex-direction: column;
}

.messageTimestamp {
    display: flex;
    justify-content: center;
    color: #999;
    font-weight: 600;
    font-size: 12px;
    margin: 10px 0px;
    text-transform: uppercase;
}

.messageContainer {
    border: 2px solid #dedede;
    background-color: #ebebeb;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    display: inline-flex;
    min-width: 300px;
    max-width: 560px;
    align-items: flex-start;
}
.messageContainer p{
    color: #333;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px;
}

.sentMessage {
   width: 100%;
   display: flex;
   align-items: center;
}

.sentMessageInner{
    border-color: #ccc;
    background-color: black;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    min-width: 300px;
    max-width: 560px;
    margin-left: auto;
    display: inline-flex;
}
.sentMessageInner p{
    color: #fff;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px;
}

.messageContainer::after {
    content: "";
    clear: both;
    display: table;
}

.messageUserImg {
    float: left;
    max-width: 30px;
    width: 100%;
    margin-right: 20px;
}

.messageUserImgRight {
    float: right;
    margin-left: 20px;
    margin-right: 0;
    min-width: 42px;
}

.messageUserTimeRight {
    float: right;
    color: #aaa;
    font-size: 11px;
}

.messageUserTimeLeft {
    float: left;
    color: #444;
    font-size: 11px;
    text-align: right;
}

.replyBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background: #ecedf1;
    border-top: 15px solid #ecedf1;
    width: 100%;
}

form {
    width: 100%;
}

.emojiPicker {
    position: absolute;
    box-shadow: 1px 1px 6px rgb(0 0 0 / 30%);
    z-index: 1;
    top: 200px;
    right: 0px;
}

.InputWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #fff;
    padding: 13px;
    min-height: 70px;
    width: 99%;
    margin: 0 0 15px;
}

.messageTextarea {
    font-size: 16px;
    font-weight: 400;
    appearance: none;
    border-radius: 0;
    overflow: auto;
    overflow-x: hidden;
    resize: none;
    border: 0;
    padding: 0;
    margin: 0 14px 0 0;
    width: 100%;
    height: 100px;
    color: #222;
    
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: normal;
    }
}
.messageTextarea:focus{
    outline: none;
}

.sendButton {
    display: flex;
    align-items: center;
    width: 150px;
    border: none;
    margin-top: 0px;
    background: #000;
    color: #fff;
    text-align: center;
    justify-content: center;
    padding: 5px;
    margin-bottom: 15px;
}

.chatBox{
    display: flex;
    align-items: center;
    width: 100%;
}
.chatContent{
    display: flex;
    flex-direction: column;
    margin-right: auto;
    width:calc(100% - 65px);
    border-radius: 10px 10px 0px;
    padding: 5px 15px;
    font-size: 16px;
    line-height: 19px;
    margin: 0px 0px 15px;
    white-space: pre-line;
}
.deleteIcon{
    cursor:pointer ; 
}


 /* Let's get this party started */
 ::-webkit-scrollbar {
    width: 4px;
}
 
/* Track */
::-webkit-scrollbar-track {
     background: #F4F4F4;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #C0C5CC; 

}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255,0,0,0.4); 
}


.readMsg{
    width:5px;
    height: 5px;
    background: red;
    border-radius: 100%;;
    margin-left: auto;
}

.conversationInfo{
    display: flex;
    align-items: center;
    width: 100%
}

.filterContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    margin-top: 20px; 
  }
  .userFilter {
    width: 100%;
    margin-right: 10px;
  }
  .filterBtn {
    margin-right: 10px;
  }
  .inputTitle {
    height: 40px !important;
  }


  .loaderDiv {
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
  }
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #cccc; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 50px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }