.ModalContent {
  position: absolute;
  padding: 0px;
  max-height: 700px;
  overflow: auto;
  max-width: 70%;
  width: 100%;
}

.OverlayContent {
  display: flex;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.2rem;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.originalContent {
  max-height: 150px;
  margin-bottom: 20px;
  overflow: auto;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 20px;
}

.userFilter {
  width: 100%;
  margin-right: 10px;
}

.filterBtn {
  margin-right: 10px;
}

.inputTitle {
  height: 40px !important;
}