.paginationWrapper {
    display: flex;
    padding-top: 30px;
    justify-content: center;
    align-items: center;
}

.pagination {
    position: relative;
    background: #fff;
    display: flex;
    padding: 10px 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.next {
    margin-left: 30px;
    font-weight: 700;
    font-size: 20px;
    list-style: none;
    line-height: 50px;
    margin: 0 5px;
}

.pageNumber {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    list-style: none;
    margin: 0 5px;
}

.active {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    list-style: none;
    margin: 0 5px;
    background-color: black;
    color: #fff;
}