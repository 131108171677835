.radioContainer {
    display: block;
    position: relative;
    padding-left: 22px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */

.radioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */

.radioCheckmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #eee;
    border-radius: 50%;
    border: 1px solid #8c8c8c;
}

/* On mouse-over, add a grey background color */

.radioContainer:hover input~.radioCheckmark {
    background-color: #ffffff;
    border: 1px solid #000;
}

/* When the radio button is checked, add a blue background */

.radioContainer input:checked~.radioCheckmark {
    background-color: #fff;
    border: 1px solid #000;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.radioCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */

.radioContainer input:checked~.radioCheckmark:after {
    display: block;
    background: #000;
}

/* Style the indicator (dot/circle) */

.radioContainer .radioCheckmark:after {
    top: 1px;
    left: 1px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #000;
}