.textareaExpand {
  resize: none;
}

div[role="columnheader"], .table thead th {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.rmsc .item-renderer {
  text-align: center;
}